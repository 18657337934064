<script>
import SocialBox from './SocialBox.vue'

export default {
  components: {
    SocialBox
  },
  data() {
    return {
      topArtist: '',
    };
  },
  methods: {
    fetchData() {
      fetch(makeLastFmUrl(), {
        method: "GET"
      })
          .then((response) => {
            response.json().then((data) => {
              let artist;
              for (let i = 0, l = data.toptracks.track.length; i < l; i++) {
                artist = data.toptracks.track[i].artist.name || 'Carly Rae Jepsen';
              }
              this.topArtist = 'Probably listening to ' + artist
            })
          })
          .catch((err) => {
            this.topArtist = 'Probably listening to Carly Rae Jepsen tbh';
            console.error(err)
          })
    }
  },
  beforeMount() {
    this.fetchData()
  }
}

function makeLastFmUrl(limit = 1, period = '1month') {
  return 'https://ws.audioscrobbler.com/2.0/?method=user.gettoptracks&user=wheresmyelvis&api_key='
      + process.env.VUE_APP_LASTFM_API_KEY +
      '&format=json&limit='
      + limit +
      '&period=' + period
}

</script>

<template>
  <SocialBox
      social-name='LastFM'
      social-handle='wheresmyelvis'
      social-link='https://last.fm/user/wheresmyelvis'
      :social-description='this.topArtist'
      social-image='fa-brands fa-lastfm fa-2xl'
  />
</template>

<style scoped>
</style>
