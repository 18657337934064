<script>
  export default {
    name: "NavBar"
  }
</script>


<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link class='navbar-item' to="/">Darke FYI</router-link>
      </div>
      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="mainNav">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>

      <div class="navbar-menu" id='mainNav'>
        <div class="navbar-start">
          <router-link class='navbar-item' to="/">Home</router-link>
          <router-link class='navbar-item' to="/socials">Socials</router-link>
        </div>
      </div>
    </nav>
</template>

<style scoped>

</style>
