<script>
import SocialBox from "./SocialBox.vue"
export default {
  components: {
    SocialBox
  }
}
</script>

<template>
  <SocialBox
      social-name='LinkedIn'
      social-handle='SimonDarke'
      social-link='https://www.linkedin.com/in/simon-darke-76178b160/'
      social-description='Exciting'
      social-image='fa-brands fa-linkedin fa-2xl'
  />
</template>

<style>
</style>
