<script>
import {createFlickr} from "flickr-sdk"
import {buildPhotoData} from "@/Api/flickr";

const {flickr} = createFlickr(process.env.VUE_APP_FLICKR_API_KEY)

const photoset = await flickr("flickr.photosets.getPhotos", {
  user_id: '55434523@N02',
  photoset_id: '72177720319322148'
})
const randomPhoto = photoset.photoset.photo[Math.floor(Math.random() * photoset.photoset.photo.length)]
const res = await flickr("flickr.photos.getInfo", {
  photo_id: randomPhoto.id,
})
let photoData = buildPhotoData(res.photo)
export default {
  data() {
    return {
      photoData: photoData
    }
  }
}
</script>

<template>
  <div class="column is-half is-offset-one-quarter">
    <div class="card">
      <div class="card-image">

        <figure class="image">
          <img :src="photoData.src" :alt="photoData.description"/>
        </figure>
      </div>
      <div class="card-content">
        <div class="level is-mobile">
          <div class="level-left">
          </div>
        </div>

        <div class="content">
          {{ photoData.title }}
          <a :href="photoData.url" target="_blank">maybe a link to it</a>
          <br>
          <a v-for="tag in photoData.tags" :key="tag" class="label">{{ tag.raw }}</a>
          <br>
          <time datetime="2018-1-1 ">{{photoData.date}}</time>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
