<script>
import SocialBox from "./SocialBox.vue"
export default {
  components: {
    SocialBox
  }
}
</script>

<template>
<SocialBox
    social-name='GitHub'
    social-handle='SimonDarke'
    social-link='https://github.com/simondarke'
    social-description='Mostly private tbh'
    social-image='fa-brands fa-github fa-2xl'
/>
</template>

<style>
</style> 
