<script>
import SocialBox from "./SocialBox.vue"
export default {
	components: {
		SocialBox
	}
}
</script>

<template>
	<SocialBox
		social-name='Spotify'
		social-handle='Simon Darke'
		social-link='https://open.spotify.com/user/simondarke'
		social-description='Look at all them playlists'
		social-image='fa-brands fa-spotify fa-2xl'
	/>
</template>


<style>
</style>
