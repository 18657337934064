<template  >
  <PhotoBox />
</template>

<script>
import PhotoBox from "@/components/PhotoBox/PhotoBox.vue";

export default {
  name: 'PhotoHome',
  components: {
    PhotoBox
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
