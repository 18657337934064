import { createRouter, createWebHistory } from 'vue-router'
import PhotoHome from '../views/PhotoHome.vue'
import SocialLinks from '../views/SocialLinks.vue'

const routes = [
  {
    path: '/',
    name: 'PhotoHome',
    component: PhotoHome
  },
  {
    path: '/socials',
    name: 'SocialLinks',
    component: SocialLinks
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
