export function buildPhotoData(photoData, size = 'b')
{
    // https://live.staticflickr.com/{server-id}/{id}_{secret}_{size-suffix}.jpg
    const baseUrl = 'https://live.staticflickr.com/'
    return {
        src: baseUrl + photoData.server + '/' + photoData.id + '_' + photoData.secret + '_' + size + '.jpg',
        description: photoData.description,
        title: photoData.title._content,
        date: photoData.dates.taken,
        tags: photoData.tags.tag,
        url: photoData.urls.url[0]._content,
    }
}