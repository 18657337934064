<template>
<div class="card">
  <div class="card-content">
    <div class="media">
      <div class="media-left">
        <figure class="image">
          <span class="icon">
            <i :class="socialImage"></i>
          </span>
        </figure>
      </div>
      <div class="media-content">
        <a class="title is-4" :href="socialLink" target="_blank" :title="socialHandle">{{ socialName }}</a>
        <p class="subtitle is-6">{{ socialDescription }}</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    name: 'SocialBox',
    props: {
        socialName: String,
        socialHandle: String,
        socialLink: String,
        socialDescription: String,
        socialImage: String
      }
  }
</script>

<style>
</style>
