<template>
	<GitHub />
	<LastFm />
	<LinkedIn />
	<SpotifyBox />
</template>

<script>
  import GitHub from '@/components/Socials/GitHub.vue';
  import LastFm from '@/components/Socials/LastFm.vue';
  import LinkedIn from '@/components/Socials/LinkedIn.vue';
  import SpotifyBox from "@/components/Socials/SpotifyBox.vue";
  export default {
    name: 'SocialLinks',
    components: {
		SpotifyBox,
		GitHub,
		LastFm,
		LinkedIn,
    }
  }
</script>

<style scoped>
</style>
